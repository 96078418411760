@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Quicksand&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    font-family: 'Quicksand', sans-serif;
    background-color: #1A1A1D;
    color: white;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
}

.content {
    overflow: auto;
    height: 100%;
}

a {
    color: white; 
    text-decoration: none;
}

ul {
    text-decoration: none;
    list-style-type: none;
}
