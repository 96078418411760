.home {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../../assets/img/background.jpg');
}

.text {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    font-size: 24px;
    height: 100%;
    justify-content: center;
}

.text span {
    width: 500px;
    text-align: justify;
}

.text h1 {
    margin-bottom: 20px;
    font-weight: 600;
}

.text button {
    margin-top: 20px;
}

.text button {
    width: 200px;
    height: 50px;
    font-size: 22px;
    color: white;
    border-radius: 5px;
    background-color: crimson;
    border: none;
}

.home-content {
    display: block;
}