.login-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginform {
    width: 450px;
    height: auto;
    border: 1px solid black;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -70px;
}

.loginform form {
    width: 100%;
    padding: 15px;
}

.loginform h2 {
    display: block;
    margin-top: 15px;
}

.loginform input {
    background: rgba(255,255,255,0.1);
    display: block;
    margin-top: 15px;
    border: none; 
    width: 100%;
    font-size: 16px;
    outline: 0;
    padding: 15px;
    background-color: #e8eeef;
    color: #8a97a0;
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) 
}

.loginform input[type=submit] {
    background-color: crimson;
    color: white;
    font-size: 18px; 
    text-align: center;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #6F2232; 
}