.navbar {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #161616;
    font-size: 20px;
    font-weight: 400;
    padding: 15px;
    height: auto;
    position: sticky;
    top: 0;
    z-index:1;
}

.navbar h1 {
    padding: 0 5%;
}

.navbar ul li {
    display: inline-block;
    padding: 0 30px;
    
}

.navbar ul {
    margin-left: auto;
}

.navbar-button {
    background-color:rgb(204, 19, 56);
    border: none;
    width: 150px;
    height: 40px;
    margin-right: 5%;
    color: rgb(255, 255, 255);
    font-size: 20px;
    border-radius: 5px;
}

.navbar-button:hover {
    cursor: pointer;
}